import { AppBar,  Box, Container, Toolbar, Typography, Link} from "@mui/material";
import { TabletAndroid,Apartment, People} from '@mui/icons-material';
import React , {useContext} from 'react';
import c4ulogo from '../images/connect4u-logo.png';
import Profile from "../authentication/Profile";
import { AuthContext } from '../authentication/AuthProvider';

export default function Header() {
  const { isAuthenticated, isAdmin, roles} = useContext(AuthContext);

  return (
    <Container disableGutters maxWidth="100%">
    <Box sx={{ flexGrow: 1, padding: 0 }}>
      <AppBar position="static">
        <Toolbar>
          <Link href="/">
            <img src={c4ulogo} alt="Connect4U Logo" style={{ height: "50px", width: "50px" }} />
          </Link>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            &nbsp;
          </Typography>
          {isAdmin && isAuthenticated &&
            <Link href="/users" color="inherit" p={1}>
              <People />
            </Link>
          }
          {isAdmin && isAuthenticated &&
            <Link href="/devices" color="inherit" p={1}>
              <TabletAndroid />
            </Link>
          }
          {isAuthenticated && !roles.some(r => r === 'C4U.Technician') &&
          <Link href="/building" color="inherit" p={1}>
              <Apartment />
            </Link>
          }
          <Box>
            <Profile />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  </Container>
  );
}
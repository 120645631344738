import { Grid, Typography, TextField } from "@mui/material";
import { useState, useEffect, Fragment } from "react";
import ResendActivationEmail from "./ResendActivationEmail";

export default function PhoneDevice(props){

    const [editMode, setEditMode] = useState(props.edit);
    const [phone, setPhone] = useState(props.device.phone);
    const [isAdmin, setIsAdmin] = useState(props.isAdmin);
    const [buildingId, setBuildingId] = useState(props.buildingId);
    const [flatId, setFlatId] = useState(props.flatId);

    useEffect( () => {   
        setEditMode(props.edit);
    },[props.edit]);

    const updatePhone = (p) => {
        setPhone(p);
        props.device.phone = p;
        props.onPhoneChanged(props.device);
    }


    return (
        <Fragment>
            <Grid item xs={4}>
                מספר טלפון
            </Grid>
            <Grid item xs={8}>
            {editMode ? 
                    <TextField 
                    autoFocus
                    required
                    margin="dense"
                    id="phone"
                    label="מספר טלפון"
                    type="text"
                    variant="standard"
                    value={phone}
                    onChange={(e) => updatePhone(e.target.value)}
                    />  : props.device.phone
             }
                
            </Grid>
            <Grid item xs={4}>
                דואר אלקטרוני
            </Grid>
            <Grid item xs={8}>
                {props.device.email}
            </Grid>
            <Grid item xs={4}>
                סטטוס
            </Grid>
            <Grid item xs={8}>
                {props.device.isAuthenticated ? 
                <Typography  sx={{ color: 'success.main' }}>משתמש רשום</Typography> : 
                <Fragment>
                    <Typography sx={{ color: 'error.main' }}>משתמש לא רשום</Typography>
                    <ResendActivationEmail email={props.device.email} buildingId={props.buildingId} flatId={props.flatId}/>
                </Fragment>
                }           
            </Grid>
            {isAdmin ?
            <Fragment>
            <Grid item xs={4}>
                מספר משתמש
            </Grid>
            <Grid item xs={8}>
                {props.device.deviceid}
            </Grid>
            </Fragment>
            : null
            }
        </Fragment>

    );
}
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useContext } from 'react';
import Buildings from "./components/building/Buildings";
import BuildingInfo from './components/building/BuildingInfo';
import FlatInfo from './components/flat/FlatInfo';
import WelcomePage from './components/WelcomePage';
import AddUser from './components/user/AddUser';
import RemoveAccount from './components/RemoveAccount';
import DeviceInfo from './components/device/DeviceInfo';
import Import from './components/building/Import';
import { AuthContext } from './components/authentication/AuthProvider';
import "./style.css"
import AllDevices from './components/device/AllDevices';
import AllUsers from './components/user/AllUsers';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppInsightsErrorBoundary,AppInsightsContext  } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import DeviceRegistrations from './components/DeviceRegistrations';
import { Buffer } from 'buffer';
import Error from './components/layout/Error';
import {ProtectedRoute} from 'ProtectedRoute';
import Unauthorised from './components/layout/Unauthorised';
import LogPage from './components/utilities/LogPage';
window.Buffer = Buffer;
toast.configure();

function App() {
   var reactPlugin = new ReactPlugin();
   var appInsights = new ApplicationInsights({
      config: {
          connectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING,
          // *** If you're adding the Click Analytics plug-in, delete the next line. ***
          enableAutoRouteTracking: true,
          extensions: [reactPlugin]
      }
  });
  appInsights.loadAppInsights();
  appInsights.addTelemetryInitializer((envelope) => {
    envelope.tags['ai.cloud.role'] = 'ManagementApp'; // Set your custom role name here
  });

  
  return (
    <div className="App">
       <AppInsightsErrorBoundary onError={(error, errorInfo) => {}} appInsights={reactPlugin} fallback={<Error />}>
       <AppInsightsContext.Provider value={reactPlugin}>
       <ToastContainer />
      <Router>
        <Routes>
          <Route path="/building/:buildingId/:flatId" element={<ProtectedRoute component={<FlatInfo />}  allowedRoles={['C4U.User']}/>} />
          <Route path="/building/:buildingId/:flatId/:deviceId" element={<ProtectedRoute component={<DeviceInfo />} allowedRoles={['C4U.User', 'C4U.Technician']}/>}  />
          <Route path="/building/:buildingId" element={<ProtectedRoute component={<BuildingInfo />}  allowedRoles={['C4U.User']}/>} />
          <Route path="/building/:buildingId/import" element={<ProtectedRoute component={<Import />} />} />
          <Route exact path="/building/" element={<ProtectedRoute component={<Buildings />} allowedRoles={['C4U.User']}/>} />
          <Route exact path="/buildings/" element={<ProtectedRoute component={<Buildings />} allowedRoles={['C4U.User']}/>} />
          <Route exact path="/devices/" element={<ProtectedRoute component={<AllDevices />} allowedRoles={['C4U.Technician']} />} />
          <Route exact path="/devices/log/:type/:id" element={<ProtectedRoute component={<LogPage />} allowedRoles={['C4U.Technician']} />} />
          <Route exact path="/users/" element={<ProtectedRoute component={<AllUsers />} />} />
          <Route exact path="/registrations/" element={<ProtectedRoute component={<DeviceRegistrations />} />} />
          <Route exact path="/add-user/:token" element={<AddUser />}  />
          <Route exact path="/remove-account/" element={<RemoveAccount />} />
          <Route exact path="/" element={<WelcomePage />} />
          <Route exact path="/error" element={<Error />} />
          <Route exact path="/unauthorised" element={<Unauthorised />} />
        </Routes>
      </Router>
      </AppInsightsContext.Provider>
      </AppInsightsErrorBoundary>
    </div>

  );
}



export default App;

import React , { useState, useContext, Fragment, useEffect, useRef} from 'react'
import { AuthContext, isAdmin } from '../authentication/AuthProvider';
import { ListItemText, List, ListItem, ListItemIcon, Divider, Button, Typography, Grid} from '@mui/material';
import {Fence,AddCircleOutline,SmartScreen, Screenshot, LocalPolice} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import FilterList from '../buttons/FilterList';
import AddDevice from './AddDevice';
import { useLocation } from 'react-router-dom';


export default function DeviceList({devicesinfo, buildingId, flatId, setRefresh}) {

    const [devicesData, setDevicesData] = useState(null);
    const [initialDevices, setInitialDevices] = useState(null);
    const { requestHeaders, isAdmin} = useContext(AuthContext);
    const [showAddDevice, setShowAddDevice] = useState(false);  
    const [deviceType, setDeviceType] = useState(null);
    const [filterValue, setFilterValue] = useState('');

    const location = useLocation();
    const initialReadRef = useRef(false); //in dev mode, useEffect is called twice, so we need to read the value only once

    //read the filter value from local storage when navigating back to page  
    useEffect(() => {
      if (!initialReadRef.current) {
      const storedFilterValue = localStorage.getItem('filterValue') || '';
      console.log('location useEffect ' + storedFilterValue);
      setFilterValue(storedFilterValue);
      initialReadRef.current = true;
      }
    }, [location]);

    useEffect(() => {
      localStorage.setItem('filterValue', filterValue);
    }, [filterValue]);

        
    useEffect(() => {
        setDevicesData(devicesinfo);
        setInitialDevices(devicesinfo);
    }, [devicesinfo]);

    const onFiltered = (filtered, value) => {
        setDevicesData(filtered);
        setFilterValue(value); // Update the filter value
      };

      const navigate = useNavigate();
    
      const handleDeviceClick = (e, deviceId) => {
        navigate(`/building/`+buildingId + "/0/" + deviceId);
    };   

    const startAdd = (deviceType='base') => {
      setDeviceType(deviceType);
      setShowAddDevice(false);
      //workaround to fix the issue with the dialog not showing - need to render component twice.
      setTimeout(() => setShowAddDevice(true), 0);
    }

return(
    <Fragment>
      <List 
      sx={{
        pt: 0,
        bgcolor: 'background.paper',
        boxShadow: 3,
        marginBottom: 2,
        minWidth: 400,
        height: { xs: '310px', sm: 'auto' }, 
        overflowY: 'auto'
    }}>
        <ListItem sx={{backgroundColor:"grey.200"}} key="device-list-header">
            <Typography variant='h4' color="GrayText"  >רשימת מכשירים</Typography>
        </ListItem>
        <ListItem alignItems='flex-start' key="device-list-subheader">
        <Grid container spacing={2} justifyContent="space-between" alignItems='center'>
          <Grid item xs={6}> 
            <FilterList data={initialDevices} onFilter={onFiltered} fields="['friendlyName','deviceid']" filterValue={filterValue}/>    
          </Grid>
            
          {isAdmin && 
          <Grid item xs={6}> 
            <Button variant="contained" size="medium" startIcon={<AddCircleOutline  />} onClick={()=>startAdd()} sx={{ margin:'5px' }}> &nbsp; מכשיר&nbsp;</Button>
            {flatId===0 &&
                <Button variant="contained" size="medium" startIcon={<AddCircleOutline />} onClick={()=>startAdd("guard")} sx={{ margin:'5px' }}>&nbsp; שומר&nbsp;
                </Button>
              }
          </Grid>

          }

        </Grid>
        </ListItem>
        {devicesData && devicesData.map((device) => (
        <div key={device.deviceid} >
          <Divider variant="fullwidth" component="li" />
          <ListItem alignItems='flex-start' onClick={(e)=>{handleDeviceClick(e,device.deviceid)}}
              sx={{
              '&:hover': {
                backgroundColor: 'grey.200' // change this to your preferred color
              },
            }}>
            <ListItemIcon>
                {device.devicetype==='base'?<Screenshot />:device.devicetype==='guard'? <LocalPolice/> :device.devicetype==='gate'?<Fence /> :<SmartScreen />}
            </ListItemIcon>
            <ListItemText id={device.deviceid} primary={device.friendlyName}  />
            <ListItemText id={device.deviceid}  secondary={device.devicetype==='base'?'אינטרקום':device.devicetype==='guard'?'שומר' :device.devicetype==='gate'?'שער' : 'מוניטור'} />
          </ListItem>
        </div>
              ))}
      </List>
      <AddDevice show={showAddDevice} buildingId={buildingId} flatId="0" onAddDevice={setRefresh} deviceType={deviceType} onComplete={()=>setShowAddDevice(false)}/>
    </Fragment>
);
}
import React, { useState, useContext, Fragment } from 'react';
import { AuthContext } from '../authentication/AuthProvider';
import Header from '../layout/Header';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TextField, Button, Grid, Typography, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PhoneIphone, Search } from '@mui/icons-material';

function AllUsers() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [acsid, setAcsid] = useState('');
    const [devices, setDevices] = useState([]);
    const appInsights = useAppInsightsContext();
    const track = useTrackEvent(appInsights, "ManagementApp");
    const { requestHeaders} = useContext(AuthContext);
    const navigate = useNavigate();

    const handleInputChange = (setter) => (e) => {
        setName('');
        setEmail('');
        setAcsid('');
        setter(e.target.value);
    };

    const handleSearch = async () => {
        try {
            track("AllUsers: handleSearch");

            const targetServer = process.env.REACT_APP_API_HOST;
            const api = `api/GetPhones`;

            let query = [];
            if (name) query.push(`name=${name}`);
            if (email) query.push(`email=${email}`);
            if (acsid) query.push(`acsid=${acsid}`);
            const queryString = query.length ? `?${query.join('&')}` : '';

            fetch(targetServer + api + queryString, {
                method: 'GET',
                headers: requestHeaders
            }).then(response => {
                if (response.ok) {
                    //return the response json for the next promise in the chain
                    return response.json();
                } else {
                    throw new Error(response.status + " - " + response.statusText);
                }
            }).then(data => {
                console.log(data);
                setDevices(data);
            }).catch(error => {
                console.error('Error fetching device list:', error);
            });

        } catch (error) {
            console.error('Error fetching devices:', error);
        }
    };

    const handleDeviceClick = (e, deviceId, buildingId, flatId) => {
        navigate(`/building/`+buildingId + "/"+flatId+"/" + deviceId);
    };
    
    return (
        <Fragment>
            <Header />

            
            <Grid container spacing={2} maxWidth="md" justifyContent="center" alignItems="center"
            sx={{
              pt: 0,
              bgcolor: 'background.paper',
              boxShadow: 3,
              margin: '0 auto', // Center the grid container itself
              mt: '50px',
              mb:'10px',
              pb:2,
              maxWidth: {xs: '90%', sm:'40%'},
              display: 'flex',
              alignItems: 'stretch', // Ensure children stretch to the same height
            }}
            > 
                <Grid item xs={12} sx={{backgroundColor: 'grey.200',pb:2, }} >
                    <Typography variant="h6" align="center">חיפוש דייר.ת</Typography>
                </Grid>

                <Grid item xs={12} sx={{m:2}}>
                    <TextField
                        label="Name"
                        value={name}
                        onChange={handleInputChange(setName)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sx={{m:2}}>
                    <TextField
                        label="Email"
                        value={email}
                        onChange={handleInputChange(setEmail)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sx={{m:2}}>
                    <TextField
                        label="ACSID"
                        value={acsid}
                        onChange={handleInputChange(setAcsid)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={handleSearch} startIcon={<Search  />}>
                    &nbsp; חיפוש&nbsp;
                    </Button>
                </Grid>
            </Grid>


            <List className='list-container'
              sx={{
                pt: 0,
                bgcolor: 'background.paper',
                boxShadow: 3,
                margin: '0 auto', // Center the grid container itself
                marginBottom: 1,
                maxWidth: {xs: '90%', sm:'40%'},
                height: { xs: '310px', sm: 'auto' }, 
                overflowY: 'auto'
                }}>
              <ListItem sx={{backgroundColor:"grey.200"}} key="flat-list-header">
                  <Typography variant='h4' color="GrayText">תוצאות</Typography>
              </ListItem>
              {devices && devices.map((device) => (
           <div key={device.deviceid}>
              <Divider variant="fullwidth" component="li" />
            <ListItem alignItems='flex-start' onClick={(e)=>{handleDeviceClick(e,device.deviceid, device.buildingid, device.flatid)}}
             sx={{
              '&:hover': {
                backgroundColor: 'grey.200' // change this to your preferred color
              },
            }}>
              <ListItemIcon>
                      <PhoneIphone />
              </ListItemIcon>
              <ListItemText id={device.deviceid}  primary={device.displayName} />
</ListItem>
</div>
        ))}

              </List>
              </Fragment>
    );
}

export default AllUsers;
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './components/authentication/AuthProvider';

const ProtectedRoute = ({ component, allowedRoles }) => {
  const { isAuthenticated, isLoading, roles } = useContext(AuthContext);

  // Add default allowed roles if not provided
  allowedRoles = allowedRoles
    ? allowedRoles.concat(['C4U.God', 'C4U.Admin'])
    : ['C4U.God', 'C4U.Admin'];

    console.log('Allowed Roles:', allowedRoles);  
    console.log('User Roles:', roles);
  const forceAuthentication =
    process.env.REACT_APP_ALLOW_UNAUTHENTICATED !== 'true';

  // If authentication data is still loading, render a loading indicator or null
  if (isLoading) {
    // You can return a loading spinner or message here
    // For simplicity, we'll return null to render nothing
    console.log('Auth data is loading...');
    return null;
  }
  // **New Code End**

  if (!isAuthenticated && forceAuthentication) {
    // Redirect to the login page if the user is not authenticated
    return <Navigate to="/" />;
  }

  console.log('Allowed Roles:', allowedRoles);
  console.log('User Roles:', roles);

  if (roles && roles.some((r) => allowedRoles.includes(r))) {
    // User is authorized, render the component
    console.log('User is authorized');
    return component;
  } else {
    // User is not authorized, redirect to unauthorized page
    console.log('User is unauthorized');
    return <Navigate to="/unauthorised" />;
  }
  
};

export {ProtectedRoute};
import React , { useState, Fragment, useEffect, useRef,useContext} from 'react'
import { ListItemText, List, ListItem, ListItemIcon, Divider, Typography, Button, Grid} from '@mui/material';
import {Apartment, Publish} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import FilterList from '../buttons/FilterList';
import { AuthContext } from '../authentication/AuthProvider';
import { useLocation } from 'react-router-dom';



export default function FlatList({flatinfo, buildingId, setRefresh}) {

    const [flatsData, setFlatsData] = useState(null);
    const [initialFlats, setInitialFlatsData] = useState(null);
    const { requestHeaders, isAdmin} = useContext(AuthContext);

    //initialise filter value from local storage if exists
    const [filterValue, setFilterValue] = useState(() => {
      return localStorage.getItem('flatFilterValue') || '';
    });

    const location = useLocation();
    const initialReadRef = useRef(false);
    const navigate = useNavigate();

    //read the filter value from local storage when navigating back to page  
    useEffect(() => {
      console.log('location useEffect fired - ' + location);
      if (!initialReadRef.current) {
        const storedFilterValue = localStorage.getItem('flatFilterValue') || '';
        console.log('location useEffect ' + storedFilterValue);
        setFilterValue(storedFilterValue);
        console.log('filter value set to ' + storedFilterValue);
        initialReadRef.current = true;
      }
    }, [location]);

    
    useEffect(() => {
      console.log('filter value changed, storing - ' + filterValue);
      localStorage.setItem('flatFilterValue', filterValue);
      console.log('filter value stored - ' + filterValue);
      
    }, [filterValue]);

    useEffect(() => {
        setFlatsData(flatinfo);
        setInitialFlatsData(flatinfo);
    }, [flatinfo]);

    const onFiltered = (filtered,value) => {
        setFlatsData(filtered);
        console.log('filter value: ' + value);  
        setFilterValue(value);
      };

   
    
    const handleClick = (e,flatid) => {
      //no drill down to lobby flat
      if(flatid==="0" || flatid===0)
        return;
      navigate(`/building/${buildingId}/${flatid}`);
    };

    const importFile = () => {
        navigate(`/building/${buildingId}/import`);
    }

return(
    <Fragment>
      <List className='list-container'
        sx={{
          pt: 0,
          bgcolor: 'background.paper',
          boxShadow: 3,
          marginBottom: 0,
          minWidth: 400,
          height: { xs: '310px', sm: 'auto' }, 
          overflowY: 'auto'
          }}>
        <ListItem sx={{backgroundColor:"grey.200"}} key="flat-list-header">
            <Typography variant='h4' color="GrayText">רשימת דירות</Typography>
        </ListItem>

        <ListItem alignItems='flex-start' key="flat-list-subheader">
          <Grid container spacing={2} justifyContent="space-between" alignItems='center'>
            <Grid item xs={6}> 
              <FilterList 
                data={initialFlats} 
                onFilter={onFiltered} 
                filterValue={filterValue}
                fields="['flatname','familyName','username','deviceid','ACSID']"/>    
            </Grid>
            {isAdmin && 
            <Grid item xs={6} container justifyContent="flex-end">
              <Button variant="contained" size="medium" startIcon={<Publish  />} onClick={()=>importFile()} > 
                &nbsp; יבוא דיירים &nbsp;
              </Button>
            </Grid>
          }
          </Grid>
        </ListItem>
        {flatsData && flatsData.map((flat) => (
          <div key={flat.flatid}>
            <Divider variant="fullwidth" component="li" />
            <ListItem alignItems='flex-start' onClick={(e)=>{handleClick(e,flat.flatid)}}
             sx={{
              '&:hover': {
                backgroundColor: 'grey.200' // change this to your preferred color
              },
            }}>
              <ListItemIcon>
                      <Apartment />
              </ListItemIcon>
              <ListItemText id={flat.id}  primary={flat.familyName} />
              <ListItemText id={flat.id} secondary={flat.flatname}  />
            </ListItem>
          </div>
            ))}
      </List>

    </Fragment>
);
}
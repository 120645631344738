import React from 'react';
import { AppBar,  Box, Container, Toolbar, Typography, Link, List, ListItem, ListItemText} from "@mui/material";
import c4ulogo from '../images/connect4u-logo.png';

function Error() {
  return (
    <Container disableGutters maxWidth="100%">
    <Box sx={{ flexGrow: 1, padding: 0 }}>
      <AppBar position="static">
        <Toolbar>
          <Link href="/building">
            <img src={c4ulogo} alt="Connect4U Logo" style={{ height: "50px", width: "50px" }} />
          </Link>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            &nbsp;
          </Typography>
          <Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
    <div className="position-absolute top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
    <List className='list-container'
    sx={{
      pt: 0,
      bgcolor: 'background.paper',
      boxShadow: 3,
      minWidth: 400,
      marginBottom: 2
    }}>
              <ListItem sx={{backgroundColor:"grey.200"}}>
        <div className="d-flex flex-row justify-content-center align-items-center w-100">
          <img src={c4ulogo} alt="Connect4U Logo" style={{height: "80px", width: "80px"}}></img>
          <Typography variant="h4" color="GrayText"> Connect 4U</Typography>
        </div>
    </ListItem>
    <ListItem sx={{backgroundColor:"grey.200"}}>
      <ListItemText sx={{textAlign:"center"}}>
        <Typography variant='h5' color={'red'}>אופס. תקלה.</Typography></ListItemText>
    </ListItem>
    <ListItem sx={{backgroundColor:"white"}}>
      <ListItemText sx={{textAlign:"center"}}>
        <Typography> ארעה שגיאה במערכת</Typography>
      </ListItemText>
    </ListItem>
    <ListItem sx={{backgroundColor:"white"}}>
      <ListItemText sx={{textAlign:"center"}}>
        <Typography> אנא נסה.י שנית יותר מאוחר</Typography>
      </ListItemText>
    </ListItem>
    </List>
    </div>
  </Container>  );
}

export default Error;
import { Stack, TextField } from "@mui/material";
import React , { useEffect} from 'react'
import { Search} from '@mui/icons-material';

export default function FilterList({filterValue, onFilter, data, fields}) {

  
  useEffect(() => {
    console.log('filtered list - filterValue changed to ' + filterValue+ ', invoking search')
    handleSearch(filterValue);
  },[filterValue]);

    const handleSearch = (filterValue) => {
      console.log('handleSearch called with filter  ' + filterValue);
        //if event supplied, raise it with calling component
        if (onFilter && (!filterValue || filterValue === '')) {
          onFilter(data, filterValue);
        }
        const regex = new RegExp(filterValue, 'gi');
        let result = [];
        try{
          if(data){
        data.forEach((item) => {
          item.searchString = flattenObject(item,fields);
          if(item.searchString.match(regex)){
            result.push(item);
          }
        });
        console.log('filter result: ' + result.length + ' for filter ' + filterValue);
        onFilter(result,filterValue);
      }
      }catch(e){
        console.log('error in filter: ' + e);
        onFilter(data,filterValue);
      }
      };

      function flattenObject(obj, fields) {
        let result = '';
        for (const prop in obj) {
          if (typeof obj[prop] === 'object' && obj[prop] !== null) {
            //if property is an object, recursively flatten it, then append result
            result += flattenObject(obj[prop],fields);
          } else {
            //append the property to result if it's not an object and is included in fields
            if (fields.length==0 || fields.includes(prop)){
              result += obj[prop];
            }
          }
        }
        return result.trim();
      }

    return(
      <Stack direction="row" alignItems="center"  justifyContent="center">
            <Search />
        <TextField
              placeholder='חיפוש'
              name='buildings'
              onChange={(e)=>handleSearch(e.target.value)}
              value={filterValue || ''}
              sx={{width: '100px',
                '& .MuiInputBase-root': {
                  height: '30px', // Set the height here
                },
                '& .MuiOutlinedInput-input': {
                  padding: '0 14px', // Adjust padding to fit the new height
                },}}
            />
        </Stack>
    );
}
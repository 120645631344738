import React, {useState, useContext, Fragment} from "react";
import {Button, List, ListItem, ListItemText, Paper, Stack, TextField, CircularProgress, Typography} from "@mui/material";
import FilterList from "../buttons/FilterList";
import { AuthContext } from "../authentication/AuthProvider";

const LogViewer = ({type, id}) => {
    const [results, setResults] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);
    const [filterValue, setFilterValue] = useState('');
    const [timeWindow, setTimeWindow] = useState('1'); // Time window for the logs
    const [loading, setLoading] = useState(false); // Loading state
    const {requestHeaders, isAdmin} = useContext(AuthContext);
    const [searched, setSearched] = useState(false);

    const loadLogs = async () => {
        setLoading(true); // Start loading
        try {
            console.log('Loading logs for device of type ' + type + ' with id ' + id + ' in the last ' + timeWindow + ' hours');
            const targetServer = process.env.REACT_APP_API_HOST;

            const api = 'api/ReadLog';

            const response = await fetch(targetServer + api + `?type=${type}&id=${id}&timeWindow=${timeWindow}`, {
                method: 'GET',
                headers: requestHeaders,
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`API request failed with status ${response.status}: ${errorData.message}`);
            }

            const rows = await response.json();
            console.log('rows: ' + JSON.stringify(rows));
            setResults(Array.isArray(rows) ? rows : []);
            setFilteredResults(Array.isArray(rows) ? rows : []);
            console.log('Loaded ' + rows.length + ' logs for device of type ' + type + ' with id ' + id + ' in the last ' + timeWindow + ' hours');
        } catch (error) {
            console.error('Failed to load logs:', error);
            setResults([]);
            setFilteredResults([]);
        } finally {
            setLoading(false); // Stop loading
            setSearched(true);
        }
    };

    const onFiltered = (filtered, value) => {
        setFilteredResults(filtered);
        setFilterValue(value); // Update the filter value
    };

    const handleTimeWindowChange = (event) => {
        const value = event.target.value;
        if (/^\d+$/.test(value) && value >= 1 && value <= 120) {
            setTimeWindow(value);
        }
    };

    return (isAdmin && 
        <Fragment>
            <Stack direction="column" justifyContent="center" sx={{paddingTop: 2}}>
            <Stack direction="row" spacing={2} sx={{paddingTop: 2, marginHorizontal: 'auto', justifyContent: 'center'}}>
                <Button variant="contained" onClick={loadLogs} style={{margin:'10px'}}>טעינת לוג</Button>
                <TextField
                    label="Time Window (hours)"
                    type="number"
                    value={timeWindow}
                    onChange={handleTimeWindowChange}
                    inputProps={{ min: 1, max: 120 }}
                    sx={{ width: '150px', margin: '10px' }}
                />
                <FilterList
                    data={results} onFilter={onFiltered} fields={[]} filterValue={filterValue}
                />
            </Stack>
            {loading ? (
                <Stack direction="row" justifyContent="center" sx={{ paddingTop: 2 }}>
                    <CircularProgress />
                </Stack>
            ) : (
                filteredResults.length > 0 ? (
                    <Paper sx={{
                        pt: 0,
                        bgcolor: 'background.paper',
                        boxShadow: 3,
                        minWidth: 400,
                        height: { xs: '310px', sm: '500px' },
                        overflowY: 'auto',
                        width: '90%', // Set the width to 90% of the screen width
                        margin: '0 auto 30px auto', // Center the list horizontally and add 30px margin from the bottom
                    }}>
                        <List
                        sx={{direction: 'ltr'}}>
                            {filteredResults.map((result, index) => (
                                <ListItem key={index} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                                    <ListItemText 
                                        primary={`${result.timestamp}: ${result.name}`} 
                                        secondary={`${result.properties}`} 
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                ) : (
                    searched && 
                    <Typography variant="h6" align="center" sx={{ padding: 2 }}>
                        לא נמצאו תוצאות
                    </Typography>
                )
            )}
            </Stack>

        </Fragment>
    );
};

export default LogViewer;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';

const BackButton = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <IconButton onClick={handleBack} aria-label="back">
      <ArrowForward/>
    </IconButton>
  );
};

export default BackButton;
import react,{Fragment} from "react";
import { useParams } from "react-router-dom";
import LogViewer from "./LogViewer";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import { Container } from "@mui/material";


const LogPage = () => {
    const { type, id } = useParams();
    return (
        <Fragment> 
            <Header/>
            <Container maxWidth="md" sx={{pt:2}}>
                <LogViewer type={type} id={id}/>
            </Container>
            <Footer/>
        </Fragment>
    );
};

export default LogPage;
import React, { createContext, useState, useEffect } from 'react';
import jwt from 'jwt-decode' // import dependency

// Create a new context
export const AuthContext = createContext();

// Create a provider component to manage the authentication state
export const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState({});
  const [username, setUsername] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [accessToken, setAccessToken] = useState(null);
  const [requestHeaders, setHeaders] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [userId, setUserId] = useState('');
  const [roles, setRoles] = useState([]); // New state for roles

  useEffect(() => {
    const api = '/.auth/me';

    if (process.env.NODE_ENV === 'development') {
      console.log('Development mode, isAdmin set to true');
      setIsAdmin(true);
      setIsAuthenticated(true);
    }

    //try to load user data from session storage
    const userDataFromSession = sessionStorage.getItem('user_data');

    if (userDataFromSession) {
      var jsonUserDataFromSession = JSON.parse(userDataFromSession);
      //check if the cached user data is still valid  
      if (new Date().getTime() < new Date(jsonUserDataFromSession.expires_on)) {
        setFieldsFromAuthData(jsonUserDataFromSession);
        return;
      }
      else {
        console.log("Refreshing authentication info");
        refreshTokens();
      }
    }

    //user data not found in session storage, fetch it from the server
    fetch(api)
      .then(response => {
        console.log('response: ' + JSON.stringify(response));
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then(data => {
        if(Object.keys(data).length === 0){
          throw new Error('No data returned from auth provider');
        }
        console.log('auth provider data found');
        //save the user data 
        setUserData(data[0]);
        
        if (data[0].provider_name === 'google' || data[0].provider_name === 'apple') {
          for (let user_claim of data[0].user_claims) {
            if (user_claim.typ === 'name') {
              setUsername(user_claim.val);
            }
          }
          setUserId(data[0].user_id);
          fetchAuthenicationToken(data);
          setRoles(['C4U.User']);
        } else if (data[0].provider_name === 'aad') {
          //save the user data in session storage
          sessionStorage.setItem('user_data', JSON.stringify(data[0]));
          //set the user data
          setFieldsFromAuthData(data[0]);
        }
        
      })
      .catch(error => {
        console.log('auth provider error: ' + error);
        setIsAuthenticated(false);
        setIsLoading(false);
        setUsername('Unauthorized user');
        setRoles([]);
        console.log('setting isAdmin to false');
        if (process.env.NODE_ENV === 'development') {
          console.log('Development mode, isAdmin set to true');
          setIsAdmin(true);
          setIsAuthenticated(true);
          setUserId('dev.mode@connect4u.co.il');
          setRoles(['C4U.Admin']);

          //for testing
          /*
          setIsAdmin(false);
          setRoles(['C4U.Technician']);
          */
        }
        else {
          setIsAdmin(false);
        }
      });
  }, [accessToken]);


  const refreshTokens = async () => {
    console.log('Refreshing tokens');
    const response = await fetch('/.auth/refresh', {
      method: 'GET'
    });
    if (response.ok){
      console.log("refreshed authentication info");
      setAccessToken(null);
      sessionStorage.removeItem('user_data');
    }
  };


  const fetchAuthenicationToken = async (authenticationData) => {
      
    const api = authenticationData[0].provider_name === 'google' ? '.auth/login/google?access_type=offline' : '.auth/login/apple';
    const targetServer = process.env.REACT_APP_API_HOST;
    const response = await fetch (targetServer + api, {
      method: 'POST',
      body: JSON.stringify({
        "access_token": authenticationData[0].access_token,
        "id_token": authenticationData[0].id_token
      })});
    if (!response.ok) {
        setIsAuthenticated(false);
        setIsLoading(false);
        console.log('Error fetching authentication token');
        return;
    }
    const data = await response.json();

    setAccessToken(data.authenticationToken);
    setHeaders({
      'X-ZUMO-AUTH': `${data.authenticationToken}`,
      'Content-Type': 'application/json' // adjust the content type as per your API requirements
    })
    setIsLoading(false);
    setIsAuthenticated(true);
    
     // Decode the token to get roles
    const decodedToken = jwt(data.authenticationToken);
    if (decodedToken && decodedToken.roles) {
      setRoles(decodedToken.roles);
      console.log('Roles: ' + decodedToken.roles);  
    }
  }

  
  const setFieldsFromAuthData = (data) => {
    
    const decoded_token = jwt(data.access_token); 
    const decoded_id_token = jwt(data.id_token); 

    console.log('fields from auth data:');
    console.log('decoded_token: ' + JSON.stringify(decoded_token));
    console.log('decoded_id_token: ' + JSON.stringify(decoded_id_token));
    console.log('roles: ' + decoded_id_token.roles);

    setUsername(decoded_token.name);
    if(data.provider_name==='aad' && roles && (roles.includes('C4U.God') || roles.includes('C4U.Admin'))){
      console.log('User is admin, role: ' + roles);
      setIsAdmin(true);
    }
    setAccessToken(data.access_token);
    setUserId(data.user_id);
    setHeaders({
      'Authorization': `Bearer ${data.access_token}`,
      'Content-Type': 'application/json' // adjust the content type as per your API requirements
    });
    setIsAuthenticated(true);
    setIsLoading(false);
    
    
    if(decoded_id_token && decoded_id_token.roles){
      setRoles(decoded_id_token.roles);
      console.log('Roles: ' + decoded_id_token.roles);  
    }
    
  }



  return (
    // Provide the authentication state values to the components
    <AuthContext.Provider value={{ userData, username, accessToken, isAuthenticated, isLoading, requestHeaders, refreshTokens, isAdmin, userId, roles }}>
      {children}
    </AuthContext.Provider>
  );
};

import {Container,Grid, Button, Typography,  TextField } from '@mui/material';
import React, { useState,useContext, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Edit, Send, Cancel, Refresh} from '@mui/icons-material';
import { AuthContext } from '../authentication/AuthProvider';
import { toast } from 'react-toastify';

export default function BuildingHeader({buildingData, buildingId, refreshData}){

    const [buildingTitle, setBuildingTitle] = useState("");
    const [buildingAddress, setBuildingAddress] = useState("");
    const [buildingCity, setBuildingCity] = useState("");
    const [buildingCountry, setBuildingCountry] = useState("");
    const [buildingEmail, setBuildingEmail] = useState("");
    const [orefArea, setOrefArea] = useState("");
    const [doorCode, setDoorCode] = useState("");
    const [doorCodeError, setDoorCodeError] = useState(false);
    const [doorDelay, setDoorDelay] = useState("");
    const [doorDelayError, setDoorDelayError] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editButtonText, setEditButtonText] = useState("עדכון");
    const { requestHeaders, isAdmin} = useContext(AuthContext);
    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if(buildingData!=null){
            resetEditFields();
        }
    }, [buildingData]);

    const onSuccess = () => {
        exitEditMode();
        refreshData();
        //refresh building list
        toast.success('הבניין עודכן בהצלחה');
    }

    const onFailure = () => {   
        toast.error('שגיאה בעדכון הבניין');
        exitEditMode();
    }

    const refreshBuilding = () => {
        refreshData();
    }


    const updateBuilding = async () => {
        const targetServer = process.env.REACT_APP_API_HOST

        const building = {
            title: buildingTitle,
            address: buildingAddress,
            city: buildingCity,
            country: buildingCountry,
            doorcode: doorCode,
            doordelay: doorDelay,
            email: buildingEmail, 
            orefArea: orefArea
        };
        const api = 'api/building/' + buildingId;
        try {
          const response = await fetch(targetServer + api ,  {
            method: 'PUT',
            headers: requestHeaders,
            body: JSON.stringify(building)
        });
        console.log('Update building sucessful! response:');
        
          onSuccess();
        } catch (error) {
          console.error(error);
          resetEditFields();
          onFailure();
        }
      };

      const resetEditFields = () => {
        if(buildingData!==null &&  buildingData!==undefined){
        setBuildingTitle(buildingData.title);
        setBuildingAddress(buildingData.fullAddress.address);
        setBuildingCity(buildingData.fullAddress.city);
        setBuildingCountry(buildingData.fullAddress.country);
        setDoorCode(buildingData.doorcode);
        setDoorDelay(buildingData.doordelay);
        setBuildingEmail(buildingData.email);
        setOrefArea(buildingData.orefArea);
        }
      }

      const cancel = () => {
        //if in edit mode, cancel edit mode
        if(editMode){
            resetEditFields();
           exitEditMode();
        }
    }
    const  enterEditMode = () => {
        if(editMode){
            updateBuilding();
            exitEditMode();

        }
        else{
        setEditMode(true);
        setEditButtonText("שמירה");
        }
    }

    const exitEditMode=()=>{
        setEditMode(false);
        setEditButtonText("עדכון");
    }

    const handleDoorCodeChange = (value) => {
        //check if doorcode is 4 digits
        const doorcodeRegex = /^\d{4}$/;
        setDoorCode(value);
        const isValid = doorcodeRegex.test(value);
        if(isValid){
            setDoorCodeError(false);
        }
        else{
             setDoorCodeError(true);
        }
        }
    const handleDoorDelayChange = (value) => {
        //reg ex that only allows for a single digit of 1-5
        const doorDelayRegex = /^[1-5]$/;
        setDoorDelay(value);
        const isValid = doorDelayRegex.test(value);
        if(isValid){
            setDoorDelayError(false);
        }
        else{
            setDoorDelayError(true);
        }
        }
    return(
        <Container maxWidth="100%">
        {buildingData ?
            <Grid container spacing={2} sx={{marginTop:{xs:'0px', sm:'10px'} }}  borderBottom={2} borderColor={"GrayText"} justifyContent={"space-between"} maxWidth="100%"> 
                {editMode?
                <Grid item xs={12} sm={8} container direction={'column'} spacing={1}>
                    <Grid item container direction={{xs: 'column', sm:'row'}}>
                        <Typography variant="h8" component="div" color={"GrayText"} margin={1}>שם הבניין </Typography>
                        <TextField placeholder="שם הבניין" value={buildingTitle} onChange={(e)=>setBuildingTitle(e.target.value)} />
                    </Grid>
                    <Grid item container direction={{xs: 'column', sm:'row'}}>
                        <Typography variant="h8" component="div" color={"GrayText"} margin={1}>כתובת </Typography>
                        <TextField placeholder="רחוב ומספר" value={buildingAddress} onChange={(e)=>{setBuildingAddress(e.target.value)}}/>
                        <TextField placeholder="עיר" value={buildingCity} onChange={(e)=>{setBuildingCity(e.target.value)}}/>
                     </Grid>
                    {editMode &&
                    <Grid item container direction="row">
                        <Grid container xs={12}>
                            <Typography variant="h8" component="div" color={"GrayText"} margin={1}>קוד פתיחה </Typography>
                            <TextField placeholder="קוד פתיחה" value={doorCode} onChange={(e)=>{handleDoorCodeChange(e.target.value)}} 
                             error={doorCodeError}
                             helperText={doorCodeError ? "קוד פתיחה צריך להיות 4 ספרות" : ""}
                             inputProps={{
                                maxLength: 4,
                              }}/>
                               <Typography variant="h8" component="div" color={"GrayText"} margin={1}>השהייה </Typography>
                            <TextField placeholder="השהיית פתיחה" value={doorDelay} onChange={(e)=>{handleDoorDelayChange(e.target.value)}} 
                                error={doorDelayError}
                                helperText={doorDelayError ? "השהיית הדלת צריכה להיות בין 1 ל-5 שניות" : ""}
                                inputProps={{
                                    maxLength: 1,
                                }}/>
                                    
                        </Grid>
                        <Grid  container xs={12}>
                           
                        </Grid>
                    </Grid>
                }
                {editMode && 
                    <Grid item container direction="row">
                        <Typography variant="h8" color={"GrayText"} margin={2}>דוא"ל בניין </Typography>
                        <TextField placeholder='דוא"ל בניין' value={buildingEmail} onChange={(e)=>{setBuildingEmail(e.target.value)}}/>
                        <Typography variant="h8" component="div" color={"GrayText"} margin={1}>איזור פיקוד העורף </Typography>
                        <TextField placeholder="אזור פיקוד העורף" value={orefArea} onChange={(e)=>{setOrefArea(e.target.value)}} />
                    </Grid>}
                </Grid>
                :
                <Grid item xs={12} sm={8} container direction={'column'} >
                    <Typography variant={isXs?'h5':'h4'} component="div" sx={{ flexGrow: 1 }} color={"GrayText"} align='right'>
                        {buildingTitle}
                    </Typography>
                    <Typography variant={isXs?'h6':'h5'} component="div" sx={{ flexGrow: 1 }} color={"GrayText"} align='right'>
                    {buildingAddress}, {buildingCity}
                    </Typography>
                    <Typography align='right'> קוד פתיחה: {doorCode}, השהייה: {doorDelay} שניות ,אזור פיקוד עורף: {orefArea}</Typography>
                    <Typography align='right'>דוא"ל בניין: {buildingEmail}</Typography>
                </Grid>
            }
            <Grid item xs={12} sm={4} container direction={isXs?'row':'column'} spacing={2} alignItems="flex-end">
                
                { isAdmin && editMode && 
                <Button variant="contained" size="medium" startIcon={<Cancel />}  sx={{ width: '120px', margin: '5px'}} onClick={cancel} >
                    &nbsp; ביטול &nbsp;
                </Button>
                }
                {isAdmin &&
                <Button variant="contained" size="medium" startIcon={editMode?<Send />:<Edit  />}  sx={{ width: '120px', margin: '5px' }} onClick={enterEditMode} > 
                    &nbsp; {editButtonText} &nbsp;
                </Button>
                }
                    
                {!editMode &&
                    <Button variant='contained' size="medium" startIcon={<Refresh />} sx={{width:'120px', margin: '5px'}} onClick={refreshBuilding} >
                        &nbsp; רענון &nbsp;
                    </Button>
                }
            </Grid>
            </Grid>
            :""
            }
        </Container>
    );
}